var site = site || {};

(function ($) {
  site.fullWidthPromoV1 = {
    init: function () {
      if ($.isFunction($.fn.flexslider)) {
        $('.full-width-promo-v1').once('full-width-promo-v1-flexslider').each(function () {
          var thisFormatter = $(this);
          var flexsliderID = thisFormatter.children('.flexslider').attr('id');
          var flexslider = thisFormatter.find('#' + flexsliderID);
          var fullScreenSliderWidth = '100%';
          var sliderWidth = thisFormatter.find('.slider-width').text();
          var itemWidth;
          var transitionTime = parseFloat(flexslider.data('transition_time') ? flexslider.data('transition_time') : 5) * 1000;

          // Check slides length and add items class if there are more than one slide
          if (thisFormatter.children('.flexslider').find('.slides li').length > 1) {
            // more than one slide
            thisFormatter.children('.flexslider').addClass('items');
          } else {
            // one slide
            thisFormatter.children('.flexslider').addClass('item');
          }

          // DoneDone #308 - CMS Template Modifications - Full Width - to set a 100% full width screen slider.
          var fsSliderWidth = false;

          if (flexslider.hasClass('fs-slider-width')) {
            fsSliderWidth = true;
          }
          // DoneDone #295 - Holiday Shop 2015 Homepage - v2 - Sometimes the brand needs a full width screen slider.
          if (sliderWidth.length > 0) {
            thisFormatter.addClass('customSliderWidth');
            flexslider.css({ 'max-width': sliderWidth + 'px' });
            flexslider.find('.slides > li').css({
              'max-width': sliderWidth + 'px',
              'width': 'auto !important'
            });
            itemWidth = sliderWidth;
          } else if ((fsSliderWidth === true) && (sliderWidth.length < 1)) {
            // DoneDone #308 - CMS Template Modifications - Full Width - to set a 100% full width screen slider.
            thisFormatter.addClass('fullScreenSliderWidth');
            flexslider.css({ 'max-width': fullScreenSliderWidth });
            itemWidth = screen.width;
            flexslider.find('.slides > li').css({
              'max-width': fullScreenSliderWidth,
              'width': itemWidth
            });
          } else if (site.client.isMobile) {
            // Force full with if it's mobile and none of the CMS options were used, setting the width to 960
            // causes slider to flicker on page load
            thisFormatter.addClass('fullScreenSliderWidth');
            flexslider.css({ 'max-width': fullScreenSliderWidth });
            itemWidth = screen.width;
            flexslider.find('.slides > li').css({
              'max-width': fullScreenSliderWidth,
              'width': itemWidth
            });
          } else {
            itemWidth = 960;
          }
          var slideshow = false;

          if (flexslider.hasClass('flexslider-slideshow')) {
            slideshow = true;
          }
          var flexsliderDir = flexslider.data('direction');
          var rtl = flexsliderDir && flexsliderDir === 'rtl' ? true : false;

          flexslider.fitVids().flexslider({
            animation: 'slide',
            before: function (slider) {
              // stop video
              var currentSlide = slider.slides.eq(slider.currentSlide);
              var currentFrame = currentSlide.find('iframe');
              var currentFrameSrc = currentFrame.attr('src');

              currentFrame.attr('src', '');
              currentFrame.attr('src', currentFrameSrc);
            },
            itemMargin: 0,
            itemWidth: itemWidth, // DoneDone #295
            minItems: 1,
            maxItems: 1,
            slideshow: slideshow,
            video: true,
            move: 1,
            animationLoop: true,
            slideshowSpeed: transitionTime,
            rtl: rtl
          });
        });
      }
    }
  };
  $(document).ready(function () {
    site.fullWidthPromoV1.init();
  });
})(jQuery);
